<template>
  <div id="invoice" class="bg-white p-5">
    <div class="flex justify-between mb-5">
      <h1 class="text-3xl font-bold">Invoice</h1>
      <div class="flex gap-5">
        <button
          @click="takeScreenshot()"
          class="flex bg-gray-100 text-white py-2 px-4 rounded-md"
        >
          <download-icon width="20" />
        </button>
        <button
          class="bg-blue-500 text-white py-2 px-4 rounded-md"
          @click="printInvoice()"
        >
          Print
        </button>
        <button
          class="bg-primary text-white py-2 px-4 rounded-md"
          @click="paymentDialogModal = true"
          v-if="!paid"
        >
          Pay Now
        </button>
      </div>
    </div>
    <div class="bg-gray-50 p-5">
      <h2 class="text-black font-bold text-sm">RECIPIENT</h2>
      <div class="flex justify-between gap-3 mt-1 text-sm">
        <div class="w-2/3 uppercase">
          <span class="text-primary">Name:</span>
          {{ invoice.contact_person }} <br />
          <span class="text-primary">Phone:</span>
          {{ invoice.contact_person_phone }} <br />
          <span class="text-primary">Tag Number:</span>
          {{ invoice.corpse_tag }}
          <h2 class="text-black font-bold text-sm mt-5 mb-1">
            CORPSE INFORMATION
          </h2>
          <span class="text-primary">Name of Deceased:</span>
          {{ invoice.name_of_deceased }}<br />
          <span class="text-primary">Date of wake keep:</span>
          {{ invoice.date_of_wake_keep || "" }}<br />
          <span class="text-primary">Date of funeral:</span>
          {{ invoice.date_of_funeral || "" }}<br />
          <span class="text-primary">Invoice generated by:</span>
          {{ invoice.staff.firstname }} {{ invoice.staff.lastname }}<br />
        </div>
        <div class="w-1/3 text-right">
          <h1 class="text-3xl font-bold mb-2">Invoice</h1>
          <h2 class="uppercase font-bold text-sm">invoice no.</h2>
          {{ invoice.corpse_id }}<br />
          <h2 class="uppercase font-bold text-sm mt-1">Invoice date</h2>
          {{ today }}<br />
          <div class="font-bold text-4xl mt-5 opacity-20" v-if="paid">Paid</div>
        </div>
      </div>
    </div>
    <div class="my-10">
      <div
        class="text-gray-400 font-bold text-xs text-right flex justify-between"
      >
        <h2 class="w-2/5 text-left">ITEM DESCRIPTION</h2>
        <h2 class="w-1/6">DAYS</h2>
        <h2 class="w-1/5">RATE</h2>
        <h2 class="w-1/5">AMOUNT</h2>
      </div>
      <div
        class="text-sm text-right flex justify-between border-b border-gray-500 py-3"
        v-for="(item, index) in invoice.invoice.invoice_items"
        :key="index"
      >
        <h2 class="w-2/5 text-left">{{ item.name }}</h2>
        <div class="w-1/5">
          <template v-if="item.name === 'Storage Fee'">
            {{ Math.ceil(dateDiff(item.created_at)) }}
          </template>
          <template v-else>{{ item.days || "-" }}</template>
        </div>
        <div class="w-1/5">
          <template v-if="item.name === 'Storage Fee'">
            {{ parseCash(item.amount) }}
          </template>
          <template v-else>{{ item.rate || "-" }}</template>
        </div>
        <h2 class="w-1/5">
          <template v-if="item.name === 'Storage Fee'">
            <template v-if="dateDiff(item.created_at) < 0">
              {{ parseCash(item.amount * dateDiff(item.created_at)) }}
            </template>
            <template v-else>{{ parseCash(item.amount) }}</template>
          </template>
          <template v-else>{{ parseCash(item.amount) }}</template>
        </h2>
      </div>
      <div class="flex justify-end">
        <div class="w-3/6"></div>
        <div
          class="w-3/6 py-3 flex justify-end border-b-2 border-gray-300 mt-3"
        >
          <h2 class="w-1/2 text-gray-400 font-bold text-xs">SUBTOTAL</h2>
          <h2 class="w-1/2 text-right">
            {{ parseCash(invoice.invoice.totalpayable) }}
          </h2>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="w-3/6"></div>
        <div
          class="w-3/6 py-3 flex justify-end border-b-2 border-gray-300 mt-3"
        >
          <h2 class="w-1/2 text-gray-400 font-bold text-xs">DISCOUNT</h2>
          <h2 class="w-1/2 text-right">
            {{ parseCash(0) }}
          </h2>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="w-3/6"></div>
        <div class="w-3/6 py-3 flex items-end justify-end mt-3">
          <h2 class="w-1/2 font-bold text-xs">TOTAL</h2>
          <h2 class="w-1/2 text-xl font-black text-right text-primary">
            {{ parseCash(invoice.invoice.totalpayable) }}
          </h2>
        </div>
      </div>
    </div>
    <p class="text-gray-400 text-xs">
      Transfer the amount to the business account below. Please include invoice
      number on your check.
    </p>
    <div class="my-4 text-sm flex gap-5 justify-end" v-if="settings">
      <div class="flex gap-4">
        <h2 class="text-gray-400">BANK:</h2>
        <p class="">{{ settings.bank }}</p>
      </div>
      <div class="flex gap-4">
        <h2 class="text-gray-400">IBAN:</h2>
        <p class="">{{ settings.account_number }}</p>
      </div>
    </div>
    <div class="text-red-500" v-else>
      Account details not set for this branch, contact your branch Manager
    </div>
    <div class="mt-10 mb-14">
      <h2 class="font-bold text-sm">NOTES</h2>
      <p class="text-gray-400 text-xs my-5">
        All amounts are in naira. Please make the payment within 5 days from the
        issue date of this invoice. <br /><br /><br /><br />
        Thank you for choosing TOS Funerals.
        <br />Signature
      </p>
    </div>
    <div
      class="border-t-2 border-gray-200 py-5 flex gap-1 justify-between items-end text-gray-500 text-xs"
    >
      <div class="w-1/3">TOS Funerals</div>
      <div class="w-1/3 px-5">
        <b class="text-primary">@</b>
        info@tos.com<br />
      </div>
      <div class="w-1/3 text-right">
        The company is registered in the business registry under no. 87650000
      </div>
    </div>
    <payment-dialog
      v-if="paymentDialogModal"
      :items="invoice.invoice.invoice_items"
      :invoiceID="invoice.corpse_id"
      @close="
        paymentDialogModal = false;
        $toast.info('Payment was closed');
      "
      @completed="
        paymentDialogModal = false;
        $toast.info('Payment is completed');
        paid = true;
      "
    />
  </div>
</template>

<script>
import FileSaver from "file-saver";
import * as htmlToImage from "html-to-image";

import moment from "moment";
import { parseMoney } from "@/utils/helpers";

export default {
  name: "Invoice",
  props: {
    invoice: {
      required: true,
      type: Object,
    },
  },
  components: {
    DownloadIcon: () => import("@/assets/icons/DownloadIcon.vue"),
    PaymentDialog: () => import("./PaymentDialog.vue"),
  },
  computed: {
    today() {
      return moment().format("DD MMMM, YYYY");
    },
    settings() {
      return this.$store.state.settings;
    },
  },
  data() {
    return {
      paymentDialogModal: false,
      paid: false,
    };
  },
  methods: {
    dateDiff(date) {
      const today = moment();
      return Math.max(0, moment.duration(today.diff(date)).asDays());
    },
    takeScreenshot() {
      let invoice = document.getElementById("invoice");
      let corpseID = this.invoice.contact_person || "Preview";
      htmlToImage
        .toBlob(invoice)
        .then(function (blob) {
          FileSaver.saveAs(blob, `${corpseID}-invoice.png`);
        })
        .catch(function (error) {
          alert(`Something went wrong ${error}`);
        });
    },
    async printInvoice() {
      let invoice = document.getElementById("invoice").innerHTML;

      const winPrint = window.open("", "", "width=800,height=900");
      winPrint.document.write(
        "<html><head><title>" + "Preview" + "</title></head><body>"
      );
      winPrint.document.write(invoice);
      winPrint.document.write("</body></html>");
      winPrint.document.close(); // necessary for IE >= 10
      winPrint.focus(); // necessary for IE >= 10*/

      winPrint.print();
      winPrint.close();
    },
    parseCash(value) {
      return parseMoney(value);
    },
  },
};
</script>

<style></style>
